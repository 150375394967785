import {ApolloClient,InMemoryCache, HttpLink} from '@apollo/client';
import fetch from 'isomorphic-fetch';
//import fetch from 'cross-fetch';

export const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: new HttpLink({
		fetch,
		uri: `https://oileteam.com/graphql`
	})
});