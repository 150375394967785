/**
 * External dependencies.
 */
 import React from 'react';
 import { ApolloProvider } from '@apollo/client';
 import { client } from "./src/context/ApolloContext";
 import "./src/css/custom.css";
 import "./src/css/font-awesome.min.css";
 import { Helmet } from "react-helmet";
 import $ from "jquery"


 $(document).ready(function () {
	$('body').on('change', function(){
		var isChecked = $('.second_listing .second_item input:checked');
		if(isChecked.length >= 1) {
			$('.second_listing').addClass('isTrueChecked');
		} else {
			$('.second_listing').removeClass('isTrueChecked');
		}
		
		var isCheckedKit = $('.kit-list .second_listing .second_item input:checked');
		if(isCheckedKit.length >= 1) {
			$('.kit-list .second_listing').addClass('isTrueChecked');
		} else {
			$('.kit-list .second_listing').removeClass('isTrueChecked');
		}

		$('[name="other_interest"]').click(function() {
			var checked = $("input[name='other_interest']:checked").length;
			if(!checked) {
			  return false;
			}
		  });
	});
});

 export const wrapRootElement = ( { element } ) => (
    <ApolloProvider client={ client }>
       <Helmet>
	    <title>Oile Team</title>
         <link rel="preconnect" href="https://fonts.googleapis.com" />
         <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
		 <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@300;400&display=swap" rel="stylesheet"/>
		 <link href="https://fonts.googleapis.com/css2?family=Port+Lligat+Slab&family=Raleway:wght@300;400&display=swap" rel="stylesheet"/>
      </Helmet>
       { element }
      </ApolloProvider>
 );
 